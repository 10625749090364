import type { AsyncOperationResponse } from 'js/src/client';
import { ApiError } from 'js/src/libs/consts';
export type Primitive = string | number | boolean | null | undefined;
export type ShallowObject = Primitive | Record<string, Primitive> | Primitive[];

export enum ErrorTypes {
    FILE_ERROR = 'FILE_ERROR',
    REQUEST_ERROR = 'REQUEST_ERROR',
    TASK_ERROR = 'TASK_ERROR',
    UNKNOWN_ERROR = 'UNKNOWN_ERROR'
}

export class UploadError extends Error {
    type: ErrorTypes;
    expected_error: boolean;
    constructor(type: ErrorTypes, message: string) {
        super(message);
        this.type = type || ErrorTypes.UNKNOWN_ERROR;
        this.expected_error = false;
    }
}

/**
 * Type guard for primitives
 */
export function isPrimitive(value: unknown): value is Primitive {
    return value === null || value === undefined || typeof value === 'string' || typeof value === 'number' || typeof value === 'boolean';
}

/**
 * Type guard for shallow objects
 */
export function isShallowObject(value: unknown): value is ShallowObject {
    if (isPrimitive(value)) {
        return true;
    }

    if (Array.isArray(value)) {
        return value.every(isPrimitive);
    }

    if (typeof value === 'object' && value !== null) {
        return Object.keys(value).every((key: string) => isPrimitive((value as Record<string, unknown>)[key]));
    }

    return false;
}

export function isError(obj: unknown): obj is Error {
    return obj instanceof Error;
}

export function isErrorObject(obj: unknown): obj is { error: Error } {
    return (
        typeof obj === 'object' &&
        obj !== null &&
        Object.prototype.hasOwnProperty.call(obj, 'error') &&
        (obj as { error: unknown }).error instanceof Error
    );
}

export function isJqXHR(obj: unknown): obj is JQuery.jqXHR {
    return (
        typeof obj === 'object' &&
        obj !== null &&
        'status' in (obj as { [key: string]: unknown })
    );
}

export function isUploadError(obj: unknown): obj is UploadError {
    return (
        obj instanceof Error &&
        'type' in obj &&
        'expected_error' in obj
    );
}

export function isApiError(obj: unknown): obj is ApiError {
    return obj instanceof ApiError;
}

// type guard for `typeof error.body === 'object' && 'error' in error.body`
export function isApiErrorWithProperty(error: unknown, property: string): error is ApiError & { body: { [key: string]: unknown }} {
    return isApiError(error) && typeof error.body === 'object' && error.body !== null && property in error.body;
}

export function isAsyncOperationResponse(obj: unknown): obj is AsyncOperationResponse {
    return (
        typeof obj === 'object' &&
        obj !== null &&
        Object.prototype.hasOwnProperty.call(obj, 'async_operation_uuid') &&
        typeof (obj as { [key: string]: unknown }).async_operation_uuid === 'string'
    );
}
