export function hasFeatureEnabled(featureFlagName: string): boolean {
    if (!window.SERVER) {
        return false;
    }
    if (!window.SERVER.USER) {
        return false;
    }
    if (!window.SERVER.USER.feature_flags) {
        return false;
    }
    return window.SERVER.USER.feature_flags.includes(featureFlagName);
}

export function getPlanDisplayName(): string | undefined {
    return SERVER?.USER?.plan_display_name;
}
