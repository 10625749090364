// Imports
import ___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/noSourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_NO_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "", ""]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"palette-primary-color-l5-primary-400": "#7969f8",
	"palette-primary-color-l5-primary-500": "#5846f6",
	"palette-primary-color-l5-primary-700": "#4636c5",
	"palette-accent-color-accent-400": "#3be8df",
	"palette-accent-color-accent-500": "#02d3da",
	"palette-accent-color-accent-600": "#01a5bb",
	"palette-feedback-success-color-success-400": "#74e687",
	"palette-feedback-success-color-success-500": "#49d66e",
	"palette-feedback-success-color-success-600": "#35b864",
	"palette-feedback-info-color-info-400": "#58d7ff",
	"palette-feedback-info-color-info-500": "#21bcff",
	"palette-feedback-info-color-info-600": "#1893db",
	"palette-feedback-warning-color-warning-400": "#ffc03f",
	"palette-feedback-warning-color-warning-500": "#ffa101",
	"palette-feedback-warning-color-warning-600": "#db8200",
	"palette-feedback-error-color-error-400": "#ff8469",
	"palette-feedback-error-color-error-500": "#ff4b38",
	"palette-feedback-error-color-error-600": "#db2b28",
	"allColors-color-l5-primary-000": "#efedfe",
	"allColors-color-l5-primary-100": "#dedbfc",
	"allColors-color-l5-primary-200": "#bcb4fb",
	"allColors-color-l5-primary-300": "#9b8ffa",
	"allColors-color-l5-primary-400": "#7969f8",
	"allColors-color-l5-primary-500": "#5846f6",
	"allColors-color-l5-primary-600": "#4f3ddd",
	"allColors-color-l5-primary-700": "#4636c5",
	"allColors-color-l5-primary-800": "#3e30ac",
	"allColors-color-l5-primary-900": "#352994",
	"allColors-color-accent-100": "#cafded",
	"allColors-color-accent-200": "#97fbe5",
	"allColors-color-accent-300": "#62f3df",
	"allColors-color-accent-400": "#3be8df",
	"allColors-color-accent-500": "#02d3da",
	"allColors-color-accent-600": "#01a5bb",
	"allColors-color-accent-700": "#017d9c",
	"allColors-color-accent-800": "#005a7e",
	"allColors-color-accent-900": "#004168",
	"allColors-color-success-100": "#dffcdb",
	"allColors-color-success-200": "#bafab8",
	"allColors-color-success-300": "#92f299",
	"allColors-color-success-400": "#74e687",
	"allColors-color-success-500": "#49d66e",
	"allColors-color-success-600": "#35b864",
	"allColors-color-success-700": "#249a5a",
	"allColors-color-success-800": "#177c4f",
	"allColors-color-success-900": "#0e6647",
	"allColors-color-info-100": "#d2fdff",
	"allColors-color-info-200": "#a6f5ff",
	"allColors-color-info-300": "#79e8ff",
	"allColors-color-info-400": "#58d7ff",
	"allColors-color-info-500": "#21bcff",
	"allColors-color-info-600": "#1893db",
	"allColors-color-info-700": "#106eb7",
	"allColors-color-info-800": "#0a4e93",
	"allColors-color-info-900": "#06387a",
	"allColors-color-warning-100": "#fff3cb",
	"allColors-color-warning-200": "#ffe49a",
	"allColors-color-warning-300": "#fed265",
	"allColors-color-warning-400": "#ffc03f",
	"allColors-color-warning-500": "#ffa101",
	"allColors-color-warning-600": "#db8200",
	"allColors-color-warning-700": "#b86600",
	"allColors-color-warning-800": "#944c00",
	"allColors-color-warning-900": "#7b3a00",
	"allColors-color-error-100": "#ffe7d7",
	"allColors-color-error-200": "#ffcaaf",
	"allColors-color-error-300": "#ffa687",
	"allColors-color-error-400": "#ff8469",
	"allColors-color-error-500": "#ff4b38",
	"allColors-color-error-600": "#db2b28",
	"allColors-color-error-700": "#b71c26",
	"allColors-color-error-800": "#931125",
	"allColors-color-error-900": "#7a0a24",
	"allColors-color-grey-100": "#fafafb",
	"allColors-color-grey-200": "#f0f0f4",
	"allColors-color-grey-300": "#dddee6",
	"allColors-color-grey-400": "#c8cbd9",
	"allColors-color-grey-500": "#9faabf",
	"allColors-color-grey-600": "#7e889a",
	"allColors-color-grey-700": "#5d6575",
	"allColors-color-grey-800": "#3d434f",
	"allColors-color-grey-900": "#24282f",
	"allColors-color-black": "#000",
	"allColors-color-white": "#fff",
	"l5-spacers": "0,4,8,12,16,20,24,32,40,48,64,80",
	"template-tile-max-width": "21rem",
	"template-tile-column-gap": "1rem",
	"template-page-collapsed-height": "498px",
	"top-slide-z-index": "45",
	"top-slide-with-editing-controls-z-index": "47",
	"voice-driven-base-scene-z-index": "44",
	"slide-marker-border-width": "1px",
	"bootstrap-breakpoint-md": "768px",
	"creator-xs-min-width": "0",
	"creator-sm-min-width": "1355px",
	"creator-md-min-width": "1500px",
	"creator-lg-min-width": "1790px",
	"creator-xl-min-width": "1975px"
};
export default ___CSS_LOADER_EXPORT___;
