/* eslint-disable @typescript-eslint/ban-ts-comment */
// @ts-nocheck

/**
 * @ts-nocheck is required to prevent typescript from complaining about
 * `__webpack_nonce__` not being defined as `const __webpack_nonce__`.
 *
 * But then eslint starts complaining about the presence of @ts-nocheck rule in the file.
 * So we disable that chck for the entire file by adding `eslint-disable @typescript-eslint/ban-ts-comment`.
 *
 * The following line is required by webpack's style-loader to add CSP nonce to inline styles.
 * Refer: https://webpack.js.org/loaders/style-loader/#nonce
 */
__webpack_nonce__ = window._CSP_NONCE;
