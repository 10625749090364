import UAParser from 'ua-parser-js';


let isMobile: boolean | null = null;

export function isOnMobileDevice(opts = { useCache: true }): boolean {
    if (opts.useCache && isMobile !== null) {
        return isMobile;
    }
    const parser = new UAParser();
    const device = parser.getDevice();
    isMobile = device.type === 'mobile';
    return isMobile;
}
