/**
 * This file contain common utility functions.
 */

/**
 * Fn converts ms to readable formats. Default format : 'mm:ss'
 * @param milliseconds
 * @param format supported formats : 'mm:ss', 'm:ss'
 * As majority videos are <= 60 min, we don't support hour (hh) as duration.
 */
const getFormattedTimer = (milliseconds: number, format = 'mm:ss') => {
    const supportedFormats = ['mm:ss', 'm:ss'];

    if (supportedFormats.indexOf(format) < 0) {
        return 'Format not supported.';
    }
    else {
        const seconds = Math.trunc((milliseconds / 1000) % 60);
        const minutes = Math.trunc((milliseconds / (1000 * 60)) % 60);

        let formattedMinutes = `${minutes}`;
        if (format === supportedFormats[0]) {
            if (minutes < 10) {
                formattedMinutes = `0${minutes}`;
            }
        }
        const formattedSeconds = (seconds < 10) ? `0${seconds}` : `${seconds}`;
        return ` ${formattedMinutes}:${formattedSeconds} `;
    }
};

/**
 * Utility fn to format the provided string and add space after desired no of characters.
 * This is useful when showing Card details or Security code to users.
 * @param sentence: input that needs to be formatted.
 * @param spacing: add a space after these many characters.
 * @returns Formatted string with space in between characters.
 * Example
 * stringSpaceFormatter('424242424242', 4)
 * 4242 4242 4242"
 * stringSpaceFormatter('424242424242', 2)
 * 42 42 42 42 42 42"
 */
const stringSpaceFormatter = (sentence = '', spacing = 4): string => {
    const regex = new RegExp(`\\w{${spacing}}(?=.)`, 'g');
    const subst = `$& `;

    return sentence.replace(regex, subst);
};

const isEmailValid = (emailVal: string): boolean => {
    const email = emailVal.trim().toLowerCase();

    if (email.length === 0) {
        return false;
    }
    if (email.indexOf('@') < 1) {
        return false;
    }
    if (email.lastIndexOf('.') < email.lastIndexOf('@')) {
        return false;
    }
    if (email.lastIndexOf('.') >= email.length - 1) {
        return false;
    }
    if (email.indexOf('@') !== email.lastIndexOf('@')) {
        // emails can only have one "@" symbol
        return false;
    }
    if (email.indexOf(' ') >= 0) {
        return false;
    }

    return true;
};

const getUserInitials = (name: string | undefined | null, email: string | undefined | null = undefined): string => {
    let userInitial = 'L5'; // default user initial
    if (name) {
        userInitial = name.slice(0, 1);
    }
    else if (email) {
        userInitial = email.slice(0, 1);
    }
    return userInitial;
};


/**
 * This lets you access copy content in the clipboard.
 * @param text : Text to be copied
 */
function copyTextToClipboard(text: string) {
    if (!window.navigator.clipboard) {
        alert(`Your browser is too old to support this feature. Here is the text to copy: ${text}`);
        return Promise.resolve();
    }

    return window.navigator.clipboard.writeText(text);
}

/**
 * Utility method that will return text trimmed with maximum of character specified by maxCharacter
 * without breaking any words.
 * @param text : Actual text that needs to be trimmed.
 * @param maxCharacter : The maximum character the trimmed text will have.
 * @returns Trimmed text.
 */
function getTrimmedTextWithoutBreakingWords(text: string, maxCharacter = 30): string {
    const trimmedText = text.trim();
    const tokens = trimmedText.split(/\s+/);
    const trimmedTokens: string[] = [];
    let remainingChars = maxCharacter;

    // If there is only a single word in the text, then return only that word trimmed
    // to the number of characters specified in maxCharacter.
    if (tokens.length === 1) {
        return tokens[0].slice(0, maxCharacter);
    }

    tokens.some((token, idx) => {
        if (remainingChars <= 0) {
            return true;
        }

        if (token.length <= remainingChars) {
            trimmedTokens.push(token);
            remainingChars -= token.length;
            // Decrease 1 for space
            remainingChars--;
        }
    });

    return trimmedTokens.join(' ');
}

function convertRemToPx(rem: string): number {
    const remValue = parseInt(rem.replace('rem', ''));

    if (remValue && !isNaN(remValue)) {
        return remValue * 16;
    }
    else {
        // eslint-disable-next-line no-console
        console.error(`Invalid rem value: ${rem}`);
        return -1;
    }
}

function stripCssUnits(cssValue: string): number {
    const rawValue = parseInt(cssValue.replace('rem', '').replace('px', ''));

    if (rawValue && !isNaN(rawValue)) {
        return rawValue;
    }
    else {
        // eslint-disable-next-line no-console
        console.error(`Invalid css value: ${cssValue}`);
        return -1;
    }
}

/**
 * When clicking on something, don't bubble up the blur event so it keeps focus (doesn't blur).
 * Attach this to a React element's `onMouseDown` handler
 *
 * When the event target is an Input element, the default behaviour is allowed
 */
function disableClickBubbling(e: React.MouseEvent): void {
    if (!(e.target instanceof HTMLInputElement)) {
        e.preventDefault();
    }
    e.stopPropagation();
}

/**
 * a convenient way to appease the typescript gods (aka typeguard) that you are indeed
 * dealing with an HTMLElement, and not just an Element. Since document.querySelector
 * (and similar functions) return Element, you can use this to check if the element
 * is an HTMLElement
 * @param element an element, like something returned from document.querySelector
 * @returns boolean
 */
export function isHtmlElement(element: Element): element is HTMLElement {
    return element instanceof HTMLElement;
}

/**
 * Return currently editing element visibility
 */
function isEditingContainerVisible(): boolean {
    const elem = document.querySelector('.currently-editing');
    if (!elem) {
        return false;
    }
    const rect = elem.getBoundingClientRect();
    const viewHeight = Math.max(document.documentElement.clientHeight, window.innerHeight);
    return !(rect.bottom < 0 || rect.top - viewHeight >= 0);
}

/**
 * Check if the event target is within a content editable
 * @param e - Event
 * @returns boolean
 */
export const isInContentEditable = (e: Event | null | undefined): boolean => {
    // Check if the target of the event is within a content editable
    // e.g the editable created for correcting transcript text
    if (!e) {
        return false;
    }
    const target = e.target;
    if (!(target instanceof HTMLElement)) {
        return false;
    }
    return target.closest('[contenteditable="true"]') !== null;
};


/**
  * params necessary to get element using the querySelector
  */
function focusAssetElement(assetId: string, sceneId: string, subsceneIndex: number) {
    const updatedAsset = document.querySelector<HTMLDivElement>(`[data-assetid="${assetId}"][data-slideid="${sceneId}"][data-subsceneindex="${subsceneIndex}"]`);
    if (updatedAsset) {
        updatedAsset.focus();
    }
}

/**
 * helper function to pluralize words based on a count
 * @param count - num of items
 * @param word - singular form of the word that should conditionally pluralize
 * @returns if count !== 1, then returns word += word + s
 */
function pluralize(count: number, word: string): string {
    return count === 1 ? word : `${word}s`;
}

/**
 * Helper function to create an absolute URL for any given path.
 * @param path [/]path[/to/some/place][?queryParams][#hash] (terms in [..] are optional)
 * @returns absolute url
 */
function getAbsoluteUrl(path: string) {
    const urlInstance = new URL(path, `${window.location.protocol}//${window.location.host}`);
    return urlInstance.href;
}

function isTesting() {
    return window.SERVER?.END_TO_END_TESTING_MODE || window.process?.env?.JEST_WORKER_ID !== undefined;
}

export {
    getFormattedTimer,
    stringSpaceFormatter,
    isEmailValid,
    getUserInitials,
    copyTextToClipboard,
    getTrimmedTextWithoutBreakingWords,
    convertRemToPx,
    stripCssUnits,
    disableClickBubbling,
    isEditingContainerVisible,
    focusAssetElement,
    pluralize,
    getAbsoluteUrl,
    isTesting
};
