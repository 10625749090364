import { defineIntegration } from '@sentry/core';
import type { Integration, IntegrationFn } from '@sentry/types';
import type { CanvasManagerInterface, CanvasManagerOptions } from '@sentry-internal/replay';
import { CanvasManager as SentryCanvasManager } from '@sentry-internal/rrweb';

interface ReplayCanvasIntegration extends Integration {
  snapshot: (canvasElement?: HTMLCanvasElement) => Promise<void>;
}

interface ReplayCanvasOptions {
  enableManualSnapshot?: boolean;
  maxCanvasSize?: [width: number, height: number];
  quality: 'low' | 'medium' | 'high';
  recordCanvas: boolean;
}

type GetCanvasManager = (options: CanvasManagerOptions) => CanvasManagerInterface;
export interface ReplayCanvasIntegrationOptions {
  enableManualSnapshot?: boolean;
  maxCanvasSize?: number;
  recordCanvas: boolean;
  getCanvasManager: GetCanvasManager;
  sampling: {
    canvas: number;
  };
  dataURLOptions: {
    type: string;
    quality: number;
  };
}

const CANVAS_QUALITY = {
    low: {
        sampling: {
            canvas: 1,
        },
        dataURLOptions: {
            type: 'image/webp',
            quality: 0.25,
        },
    },
    medium: {
        sampling: {
            canvas: 2,
        },
        dataURLOptions: {
            type: 'image/webp',
            quality: 0.4,
        },
    },
    high: {
        sampling: {
            canvas: 4,
        },
        dataURLOptions: {
            type: 'image/webp',
            quality: 0.5,
        },
    },
};

type manualSnapshotData = {
    lastTime: number,
    pendingRafId?: number,
}

type manualSnapshotRecord = Map<number, manualSnapshotData>;

const INTEGRATION_NAME = 'ReplayCanvas';
const DEFAULT_MAX_CANVAS_SIZE = 1280;

class CanvasManager extends SentryCanvasManager {
    private manualSnapshotRecord: manualSnapshotRecord = new Map();

    public override reset() {
        super.reset();
        this.manualSnapshotRecord = new Map();
    }

    public override snapshot(canvas?: HTMLCanvasElement) {
        if (!canvas) {
            return;
        }

        const takeManualSnapshot = (timestamp: DOMHighResTimeStamp) => {
            // @ts-expect-error accessing private
            const { options, mirror, manualSnapshotRecord } = this;

            if (!mirror.hasNode(canvas)) {
                return;
            }
            const id = mirror.getId(canvas);

            // Trigger a manual snapshot immediately if (all conditions must be met):
            // 1. If the canvas has not been snapshot before
            // 2. The last snapshot of this canvas was taken more than `timeBetweenSnapshots` ago
            // Otherwise, schedule a manual snapshot if (all conditions must be met):
            // 1. The last snapshot of this canvas was taken less than `timeBetweenSnapshots` ago
            // 2. There is not already a scheduled manual snapshot for this canvas
            if (manualSnapshotRecord.has(id)) {
                const { lastTime, pendingRafId } = manualSnapshotRecord.get(id)!;
                const fps = options.sampling === 'all' ? 2 : options.sampling || 2;
                const timeBetweenSnapshots = 1000 / fps;
                if (timestamp - lastTime < timeBetweenSnapshots) {
                    if (pendingRafId) {
                        return;
                    }

                    const rafId = requestAnimationFrame(takeManualSnapshot);
                    manualSnapshotRecord.set(id, { lastTime, pendingRafId: rafId });
                    // @ts-expect-error accessing private
                    this.restoreHandlers.push(() => {
                        cancelAnimationFrame(rafId);
                    });
                    return;
                }
            }

            manualSnapshotRecord.set(id, { lastTime: timestamp });
            // @ts-expect-error accessing private
            const { snapshotInProgressMap } = this;
            if (snapshotInProgressMap.get(id)) return;

            // Don't do anything if canvas height/width is 0, otherwise causes
            // `createImageBitmap()` to throw
            if (!canvas.width || !canvas.height) return;

            snapshotInProgressMap.set(id, true);
            if (!('createImageBitmap' in window)) {
                // some older browsers don't support createImageBitmap, so we just won't record the canvas for now
                return;
            }

            createImageBitmap(canvas)
                .then((bitmap) => {
                    // @ts-expect-error accessing private
                    this.worker?.postMessage(
                        {
                            id,
                            bitmap,
                            width: canvas.width,
                            height: canvas.height,
                            dataURLOptions: options.dataURLOptions,
                            maxCanvasSize: options.maxCanvasSize,
                        },
                        [bitmap]
                    );
                })
                .catch((error) => {
                    // callbackWrapper(() => {
                    throw error;
                    // })();
                });
        };
        takeManualSnapshot(performance.now());
    }
}

/** Exported only for type safe tests. */
export const _replayCanvasIntegration = ((options: Partial<ReplayCanvasOptions> = {}) => {
    const [maxCanvasWidth, maxCanvasHeight] = options.maxCanvasSize || [];
    const _canvasOptions = {
        quality: options.quality || 'medium',
        enableManualSnapshot: options.enableManualSnapshot,
        recordCanvas: options.recordCanvas !== false,
        maxCanvasSize: [
            maxCanvasWidth ? Math.min(maxCanvasWidth, DEFAULT_MAX_CANVAS_SIZE) : DEFAULT_MAX_CANVAS_SIZE,
            maxCanvasHeight ? Math.min(maxCanvasHeight, DEFAULT_MAX_CANVAS_SIZE) : DEFAULT_MAX_CANVAS_SIZE,
        ] as [number, number],
    };

    let canvasManagerResolve: (value: CanvasManager) => void;
    const _canvasManager: Promise<CanvasManager> = new Promise(resolve => (canvasManagerResolve = resolve));

    return {
        name: INTEGRATION_NAME,
        getOptions(): ReplayCanvasIntegrationOptions {
            const { quality, enableManualSnapshot, maxCanvasSize, recordCanvas } = _canvasOptions;

            return {
                enableManualSnapshot,
                recordCanvas,
                getCanvasManager: (getCanvasManagerOptions: CanvasManagerOptions) => {
                    const manager = new CanvasManager({
                        ...getCanvasManagerOptions,
                        enableManualSnapshot,
                        maxCanvasSize,
                        errorHandler: (err: unknown) => {
                            try {
                                if (typeof err === 'object') {
                                    (err as Error & { __rrweb__?: boolean }).__rrweb__ = true;
                                }
                            }
                            catch (error) {
                                // ignore errors here
                                // this can happen if the error is frozen or does not allow mutation for other reasons
                            }
                        },
                    });
                    canvasManagerResolve(manager);
                    return manager;
                },
                ...(CANVAS_QUALITY[quality || 'medium'] || CANVAS_QUALITY.medium),
            };
        },
        async snapshot(canvasElement?: HTMLCanvasElement) {
            const canvasManager = await _canvasManager;
            canvasManager.snapshot(canvasElement);
        },
    };
}) satisfies IntegrationFn<ReplayCanvasIntegration>;

/**
 * Add this in addition to `replayIntegration()` to enable canvas recording.
 */
export const replayCanvasIntegration = defineIntegration(
    _replayCanvasIntegration
) as IntegrationFn<ReplayCanvasIntegration>;
